import React from 'react'


import './ContactPanel.css'
import '../GlobalStyle.scss'


import ContactDescription from './ContactDescription.js'
import ContactForm from './ContactForm'


const ContactPanel = ({

    containerStyle,
    formRequirements,
    isLoading,
    handleSubmit,
    heading,
    subTitle,
    statement1,
    statement2,

}) => {
    return (
        <div 
            style={{...containerStyle}}
            className='contactPanel'
        
        >
            
            <ContactDescription 
            
                className='contactPanelDescriptionSpacing'
                heading={heading}
                subTitle={subTitle}
                statement1={statement1}
                statement2={statement2}    
                
            />



            <ContactForm
                formRequirements = {formRequirements}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
            />


        </div>
    )
}

export default ContactPanel




