import React from 'react'


import './ContactForm.scss'
import '../GlobalStyle.scss'
import { Button, Input } from 'reactstrap'

const validate = (value, re) =>  re.test(String(value).toLowerCase())
 
const ContactFormInputs = ({
    inputType,
    inputLabel,
    inputValue,
    handleChange,
    setState, 
    inputRe,
    valid,
    invalid
}) => {

    const inputTextAreaStyle= {
        background: '#F0F2F5',
        borderRadius: '8px',
        border: 'none',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '146.02%',
        color: '#2E2E2E',
        
    }

    if(inputType==='textarea'){
        inputTextAreaStyle.minHeight='120px'
    }

    return (
        <span className='contactFormInputContainer'>
            <p className='contactFormRequirementSpacing' >{inputLabel}
                <span className='globalHeadingStyle'>*</span>
                </p>
            <Input
                style={inputTextAreaStyle}
                type={inputType}
                value={inputValue}
                onChange={(e)=>{ handleChange(e, setState, validate, inputRe) }}
            />    
        </span>
    )
}

const ContactForm = ({
    formRequirements,
    handleSubmit,
    isLoading
}) => {
    return (
        <div className='contactForm'>
            {formRequirements.map((req , i) => <ContactFormInputs
                    inputType={req.type}
                    inputLabel={req.label}
                    inputValue={req.value}
                    handleChange={req.handleChange}
                    setState={req.setState}
                    inputRe={req.re}
                />
            )}
            <span style={{marginBottom:'0px'}} className='contactFormInputContainer'>
                <p className='contactFormRequirementSpacing' >
                    <span className='globalHeadingStyle'></span>
                </p>
                <span style={{width:'100%'}}>
                    <Button 
                        disabled={isLoading}
                        onClick={handleSubmit}
                        className='contactFormButton'
                        color='primary'
                    >
                        Contact Sales
                    </Button>
                </span>
            </span>
                
            
        </div>
    )
    }

export default ContactForm
