import React from 'react'


import './ContactDescription.scss'
import '../GlobalStyle.scss'



const ContactDescription = ({
    heading,
    subTitle,
    statement1,
    statement2='',
}) => {
    return (
        <div className='contactDescription'>
            <p className='globalHeadingStyle'>
                {heading}
            </p>
            <h2>
               {subTitle}  
            </h2>
            <p className='globalParagraphStyle'>
                {statement1}
            </p>
            {statement2 !== '' &&
            <p className='globalParagraphStyle'
                dangerouslySetInnerHTML={{__html: statement2}}
            />

            }
        </div>
    )
}



export default ContactDescription
